import { ArrowForwardIcon, Button, FlexGap, Text } from "@pancakeswap/uikit";

import Link from "next/link";
import styled from "styled-components";

export interface IUserPointProps {
  points: number;
}

const UserPointStableContainer = styled(FlexGap)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.input};
  border-radius: 20px;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 0;
  padding: 20px;
  margin-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.mediaQueries.xl} {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const UserPointStableTitle = styled(Text)`
  > span {
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`;

const UserPoint: React.FunctionComponent<React.PropsWithChildren<IUserPointProps>> = ({ points }) => {
  return (
    <UserPointStableContainer flexDirection="column" gap="16px" bg="background">
      <div>
        <UserPointStableTitle bold textTransform="uppercase" color="secondary" fontSize="16px" pr="4px">
          Points <span>earned</span>
        </UserPointStableTitle>
        {/* <Text fontWeight={500} fontSize="16px">
            POINT:
          </Text> */}
        <Text fontWeight={500} lineHeight={1} fontSize="24px" color="warningYellow">
          {Number(points).toLocaleString("en-US", { maximumFractionDigits: 4 })}
        </Text>
      </div>
      <Link style={{ height: "26px", display: "flex", alignItems: "center" }} href="/leaderboard" prefetch={false}>
        <Button p="0" variant="text">
          <Text color="primary" bold fontSize="16px" mr="8px">
            See Leaderboard
          </Text>
          <ArrowForwardIcon color="primary" />
        </Button>
      </Link>
    </UserPointStableContainer>
  );
};

export default UserPoint;

import { ChainId } from '@pancakeswap/chains'
import { Address } from 'viem'
import { FarmV3SupportedChainId } from '../../src'
import { ComputedFarmConfigV3 } from '../../src/types'
import { farmsV3 as mantleFarms } from '../mantle'

export const farmsV3ConfigChainMap: Record<FarmV3SupportedChainId, ComputedFarmConfigV3[]> = {
  // [ChainId.ETHEREUM]: [],
  // [ChainId.GOERLI]: [],
  // [ChainId.BSC]: [],
  // [ChainId.BSC_TESTNET]: [],
  // [ChainId.ZKSYNC_TESTNET]: [],
  // [ChainId.POLYGON_ZKEVM]: [],
  // [ChainId.POLYGON_ZKEVM_TESTNET]: [],
  // [ChainId.ZKSYNC]: [],
  // [ChainId.ARBITRUM_ONE]: [],
  // [ChainId.LINEA]: [],
  // [ChainId.BASE]: [],
  // [ChainId.OPBNB_TESTNET]: [],
  // [ChainId.OPBNB]: [],
  // TODO: Add Mantle Chain
  [ChainId.MANTLE]: mantleFarms,
}

export type Addresses = {
  [chainId in ChainId]?: Address
}

export const bCakeFarmBoosterV3Address: Addresses = {
  [ChainId.BSC]: '0x695170faE243147b3bEB4C43AA8DE5DcD9202752',
  [ChainId.BSC_TESTNET]: '0x56666300A1E25624489b661f3C6c456c159a109a',
  // TODO: Add Mantle Chain
  [ChainId.MANTLE]: '0x2E47e7e2d7A75D1473995441BbcE7888DC035E74',
}
export const bCakeFarmBoosterVeCakeAddress: Addresses = {
  [ChainId.BSC]: '0x625F45234D6335859a8b940960067E89476300c6',
  [ChainId.BSC_TESTNET]: '0x1F32591CC45f00BaE3A742Bf2bCAdAe59DbAd228',
  // TODO: Add Mantle Chain
  [ChainId.MANTLE]: '0x2E47e7e2d7A75D1473995441BbcE7888DC035E74',
}

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M18.5316 17.4722C18.6715 17.6127 18.7501 17.803 18.7501 18.0014C18.7501 18.1998 18.6715 18.3901 18.5316 18.5306C18.4294 18.6309 16.0144 21 12 21C8.49469 21 5.95031 18.9 4.5 17.2641V19.5C4.5 19.6989 4.42098 19.8897 4.28033 20.0303C4.13968 20.171 3.94891 20.25 3.75 20.25C3.55109 20.25 3.36032 20.171 3.21967 20.0303C3.07902 19.8897 3 19.6989 3 19.5V15C3 14.8011 3.07902 14.6103 3.21967 14.4697C3.36032 14.329 3.55109 14.25 3.75 14.25H8.25C8.44891 14.25 8.63968 14.329 8.78033 14.4697C8.92098 14.6103 9 14.8011 9 15C9 15.1989 8.92098 15.3897 8.78033 15.5303C8.63968 15.671 8.44891 15.75 8.25 15.75H5.1975C6.3525 17.1891 8.71875 19.5 12 19.5C15.375 19.5 17.4506 17.4881 17.4713 17.4675C17.6125 17.3276 17.8035 17.2495 18.0024 17.2503C18.2012 17.2512 18.3915 17.331 18.5316 17.4722ZM20.25 3.75C20.0511 3.75 19.8603 3.82902 19.7197 3.96967C19.579 4.11032 19.5 4.30109 19.5 4.5V6.73594C18.0497 5.1 15.5053 3 12 3C7.98563 3 5.57063 5.36906 5.46938 5.46938C5.3284 5.60986 5.249 5.80059 5.24865 5.99961C5.24829 6.19863 5.32702 6.38965 5.4675 6.53063C5.60798 6.6716 5.79871 6.751 5.99774 6.75135C6.19676 6.75171 6.38777 6.67298 6.52875 6.5325C6.54937 6.51188 8.625 4.5 12 4.5C15.2812 4.5 17.6475 6.81094 18.8025 8.25H15.75C15.5511 8.25 15.3603 8.32902 15.2197 8.46967C15.079 8.61032 15 8.80109 15 9C15 9.19891 15.079 9.38968 15.2197 9.53033C15.3603 9.67098 15.5511 9.75 15.75 9.75H20.25C20.4489 9.75 20.6397 9.67098 20.7803 9.53033C20.921 9.38968 21 9.19891 21 9V4.5C21 4.30109 20.921 4.11032 20.7803 3.96967C20.6397 3.82902 20.4489 3.75 20.25 3.75Z" />
    </Svg>
  );
};

export default Icon;

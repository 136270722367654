import {
  ButtonProps,
  Flex,
  IconButton,
  InputGroup,
  InputProps,
  PasteIcon,
  Text,
  Input as UIKitInput,
  useTooltip,
} from '@pancakeswap/uikit'
import { InputHTMLAttributes, PropsWithChildren, forwardRef, useCallback, useRef } from 'react'

type PasteProps = {
  handlePaste: () => void
}

const Paste = forwardRef<HTMLButtonElement, ButtonProps & PasteProps>(({ handlePaste, ...props }, ref) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(<Text>Paste</Text>, { placement: 'top' })
  return (
    <>
      <IconButton
        ref={ref}
        onClick={handlePaste}
        scale="sm"
        variant="text"
        style={{ width: 'auto', position: 'absolute', right: '0px' }}
        {...props}
      >
        <Flex ref={targetRef}>
          <PasteIcon />
        </Flex>
      </IconButton>
      {tooltipVisible && tooltip}
    </>
  )
})

const Input = forwardRef<
  HTMLInputElement,
  PropsWithChildren<PasteProps & InputProps & InputHTMLAttributes<HTMLInputElement>>
>(({ children, handlePaste, disabled, scale = 'lg', ...props }, ref) => (
  <InputGroup scale={scale} endIcon={<Paste disabled={disabled} handlePaste={handlePaste} />}>
    <UIKitInput height="100%" type="text" ref={ref} disabled={disabled} scale={scale} {...props}>
      {children}
    </UIKitInput>
  </InputGroup>
))

/**
 * Usage example
 * export const ApplyReferral = () => {
 * const { Base, Props } = usePasteInput()

 * return (
 *  <>
 *    <Input {...Props} />
 *  </>
 * )
 * }
 */
export const usePasteInput = () => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handlePaste = async (): Promise<string | undefined> => {
    const clipboardString = await navigator.clipboard.readText()
    if (!inputRef.current) return clipboardString
    if (inputRef.current?.value === undefined) return clipboardString
    inputRef.current.value = clipboardString
    return clipboardString
  }

  const getValue = useCallback(() => {
    if (!inputRef.current) return ''
    if (inputRef.current?.value === undefined) return ''
    return inputRef.current.value
  }, [])

  return {
    Input,
    Props: {
      ref: inputRef,
      handlePaste,
    },
    getValue,
  }
}

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 28 28" {...props}>
      <rect width="28" height="28" rx="14" fill="currentColor" />
      <path
        d="M14.875 7C14.875 6.51675 14.4832 6.125 14 6.125C13.5168 6.125 13.125 6.51675 13.125 7L14.875 7ZM13.3813 21.6187C13.723 21.9604 14.277 21.9604 14.6187 21.6187L20.1872 16.0503C20.5289 15.7085 20.5289 15.1545 20.1872 14.8128C19.8455 14.4711 19.2915 14.4711 18.9497 14.8128L14 19.7626L9.05025 14.8128C8.70854 14.4711 8.15452 14.4711 7.81281 14.8128C7.47111 15.1545 7.47111 15.7085 7.81281 16.0503L13.3813 21.6187ZM13.125 7L13.125 21L14.875 21L14.875 7L13.125 7Z"
        fill="black"
      />
    </Svg>
  );
};

export default Icon;

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 15 15" {...props}>
      <path d="M6.08024 10.8949C6.05738 10.9857 5.4861 13.125 2.34371 13.125C2.21939 13.125 2.10016 13.0756 2.01226 12.9877C1.92435 12.8998 1.87496 12.7806 1.87496 12.6562C1.87496 9.51386 4.01422 8.94257 4.10504 8.91972C4.22571 8.88958 4.35341 8.9086 4.46006 8.97261C4.5667 9.03661 4.64355 9.14036 4.67369 9.26103C4.70384 9.3817 4.68482 9.5094 4.62081 9.61605C4.5568 9.72269 4.45305 9.79954 4.33239 9.82968C4.27965 9.84433 3.01871 10.2129 2.83473 12.1652C4.78707 11.9812 5.15621 10.7227 5.17145 10.6641C5.20253 10.5435 5.28021 10.4403 5.3874 10.3771C5.49459 10.3138 5.62252 10.2958 5.74303 10.3269C5.86354 10.3579 5.96677 10.4356 6.03001 10.5428C6.09325 10.65 6.11132 10.7779 6.08024 10.8984V10.8949ZM11.5295 6.9457L11.25 7.22519V10.643C11.2507 10.7666 11.2268 10.889 11.1797 11.0033C11.1326 11.1176 11.0633 11.2214 10.9757 11.3086L8.96481 13.3184C8.87809 13.4057 8.77493 13.475 8.66129 13.5223C8.54765 13.5695 8.42578 13.5938 8.3027 13.5937C8.20112 13.5937 8.10021 13.5773 8.00387 13.5451C7.83845 13.4904 7.69164 13.3905 7.58002 13.2567C7.4684 13.123 7.39642 12.9606 7.37223 12.7881L7.05758 10.5328L4.46715 7.94238L2.21305 7.62773C2.04028 7.6035 1.8777 7.53154 1.7436 7.41995C1.6095 7.30836 1.50919 7.16156 1.45397 6.99608C1.39875 6.83059 1.39079 6.65297 1.43101 6.48321C1.47122 6.31345 1.55801 6.15828 1.6816 6.03515L3.69137 4.02422C3.7786 3.93667 3.88237 3.86734 3.99664 3.82026C4.11092 3.77319 4.23341 3.7493 4.35699 3.75H7.77477L8.05426 3.4705C9.61754 1.90781 11.5007 1.84043 12.2384 1.88437C12.467 1.89826 12.6826 1.99532 12.8445 2.15723C13.0064 2.31915 13.1035 2.53472 13.1173 2.76328C13.1601 3.49863 13.0927 5.38242 11.53 6.9457H11.5295ZM6.83727 4.6875H4.35699L2.34371 6.69961L4.52047 7.00312L6.83727 4.6875ZM10.3125 8.16269L7.99625 10.4789L8.30094 12.6562L10.3125 10.643V8.16269Z" />
    </Svg>
  );
};

export default Icon;

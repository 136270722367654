import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path d="M16.9132 8.1633L10.6632 14.4133C10.5762 14.5007 10.4727 14.57 10.3587 14.6174C10.2447 14.6647 10.1226 14.689 9.99918 14.689C9.8758 14.689 9.75362 14.6647 9.63967 14.6174C9.52571 14.57 9.42222 14.5007 9.33512 14.4133L3.08512 8.1633C2.909 7.98718 2.81006 7.74831 2.81006 7.49923C2.81006 7.25016 2.909 7.01129 3.08512 6.83517C3.26124 6.65905 3.50011 6.56011 3.74918 6.56011C3.99826 6.56011 4.23713 6.65905 4.41325 6.83517L9.99997 12.4219L15.5867 6.83439C15.7628 6.65827 16.0017 6.55933 16.2507 6.55933C16.4998 6.55933 16.7387 6.65827 16.9148 6.83439C17.0909 7.01051 17.1899 7.24938 17.1899 7.49845C17.1899 7.74752 17.0909 7.9864 16.9148 8.16252L16.9132 8.1633Z" />
    </Svg>
  );
};

export default Icon;

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 32 32" {...props}>
      <path
        d="M5.29249 10.7075C5.19952 10.6146 5.12576 10.5043 5.07543 10.3829C5.02511 10.2615 4.99921 10.1314 4.99921 9.99999C4.99921 9.86858 5.02511 9.73845 5.07543 9.61705C5.12576 9.49566 5.19952 9.38537 5.29249 9.29249L9.29249 5.29249C9.43235 5.15248 9.6106 5.05712 9.80469 5.01846C9.99877 4.97981 10.2 4.99962 10.3828 5.07537C10.5656 5.15112 10.7218 5.27942 10.8317 5.44401C10.9416 5.60861 11.0001 5.8021 11 5.99999V8.99999H26C26.2652 8.99999 26.5196 9.10535 26.7071 9.29289C26.8946 9.48042 27 9.73478 27 9.99999C27 10.2652 26.8946 10.5196 26.7071 10.7071C26.5196 10.8946 26.2652 11 26 11H11V14C11.0001 14.1979 10.9416 14.3914 10.8317 14.556C10.7218 14.7206 10.5656 14.8489 10.3828 14.9246C10.2 15.0004 9.99877 15.0202 9.80469 14.9815C9.6106 14.9429 9.43235 14.8475 9.29249 14.7075L5.29249 10.7075ZM26.7075 21.2925L22.7075 17.2925C22.5676 17.1525 22.3894 17.0571 22.1953 17.0185C22.0012 16.9798 21.8 16.9996 21.6172 17.0754C21.4344 17.1511 21.2781 17.2794 21.1683 17.444C21.0584 17.6086 20.9998 17.8021 21 18V21H5.99999C5.73478 21 5.48042 21.1054 5.29289 21.2929C5.10535 21.4804 4.99999 21.7348 4.99999 22C4.99999 22.2652 5.10535 22.5196 5.29289 22.7071C5.48042 22.8946 5.73478 23 5.99999 23H21V26C20.9998 26.1979 21.0584 26.3914 21.1683 26.556C21.2781 26.7206 21.4344 26.8489 21.6172 26.9246C21.8 27.0004 22.0012 27.0202 22.1953 26.9815C22.3894 26.9429 22.5676 26.8475 22.7075 26.7075L26.7075 22.7075C26.8005 22.6146 26.8742 22.5043 26.9246 22.3829C26.9749 22.2615 27.0008 22.1314 27.0008 22C27.0008 21.8686 26.9749 21.7385 26.9246 21.6171C26.8742 21.4957 26.8005 21.3854 26.7075 21.2925Z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </Svg>
  );
};

export default Icon;

import { CurrencyParams, getCurrencyKey, getCurrencyListUsdPrice } from '@pancakeswap/utils/getCurrencyPrice'
import BN from 'bignumber.js'
import { FarmV2SupportedChainId, supportedChainIdV2 } from '../const'
import { isStableFarm } from '../types'
import { getFarmLpTokenPrice, getFarmsPrices } from './farmPrices'
import {
  FetchFarmsParams,
  FormatStableFarmResponse,
  StableLpData,
  evmNativeStableLpMap,
  fetchMasterChefData,
  formatClassicFarmResponse,
  formatStableFarm,
  getClassicFarmsDynamicData,
  getFarmAllocation,
  getStableFarmDynamicData,
} from './fetchFarmsV2'
import { fetchPublicFarmsData } from './fetchPublicFarmData'
import { fetchStableFarmData } from './fetchStableFarmData'

export async function farmV2FetchFarms({
  farms,
  provider,
  isTestnet,
  masterChefAddress,
  chainId,
  totalRegularAllocPoint,
  totalSpecialAllocPoint,
}: FetchFarmsParams) {
  console.log('MAMA2')
  if (!supportedChainIdV2.includes(chainId)) {
    return []
  }
  console.log(farms, 'MAMA2')
  const stableFarms = farms.filter(isStableFarm)
  console.log(stableFarms, 'MAMA3')

  console.log(chainId, masterChefAddress, 'MAMA5')

  const [stableFarmsResults, poolInfos, lpDataResults] = await Promise.all([
    fetchStableFarmData(stableFarms, chainId, provider),
    fetchMasterChefData(farms, isTestnet, provider, masterChefAddress),
    fetchPublicFarmsData(farms, chainId, provider, masterChefAddress),
  ])

  console.log('MAMA7')

  const stableFarmsData = (stableFarmsResults as StableLpData[]).map(formatStableFarm)

  const stableFarmsDataMap = stableFarms.reduce<Record<number, FormatStableFarmResponse>>((map, farm, index) => {
    return {
      ...map,
      [farm.pid]: stableFarmsData[index],
    }
  }, {})

  const lpData = lpDataResults.map(formatClassicFarmResponse)

  const farmsData = farms.map((farm, index) => {
    console.log(farm, 'MAMA733')

    try {
      return {
        ...farm,
        ...(stableFarmsDataMap[farm.pid]
          ? getStableFarmDynamicData({
              ...lpData[index],
              ...stableFarmsDataMap[farm.pid],
              token0Decimals: farm.token.decimals,
              token1Decimals: farm.quoteToken.decimals,
              price1: stableFarmsDataMap[farm.pid].price1,
            })
          : getClassicFarmsDynamicData({
              ...lpData[index],
              ...stableFarmsDataMap[farm.pid],
              token0Decimals: farm.token.decimals,
              token1Decimals: farm.quoteToken.decimals,
            })),
        ...getFarmAllocation({
          allocPoint: poolInfos[index]?.allocPoint,
          isRegular: poolInfos[index]?.isRegular,
          totalRegularAllocPoint,
          totalSpecialAllocPoint,
        }),
      }
    } catch (error) {
      console.log(error, 'MAMA733')

      console.error(error, farm, index, {
        allocPoint: poolInfos[index]?.allocPoint,
        isRegular: poolInfos[index]?.isRegular,
        token0Decimals: farm.token.decimals,
        token1Decimals: farm.quoteToken.decimals,
        totalRegularAllocPoint,
        totalSpecialAllocPoint,
      })
      throw error
    }
  })

  const decimals = 18
  const farmsDataWithPrices = getFarmsPrices(
    farmsData,
    evmNativeStableLpMap[chainId as FarmV2SupportedChainId],
    decimals,
  )

  console.log(farmsDataWithPrices, 'WIZZ')

  const tokensWithoutPrice = farmsDataWithPrices.reduce<Map<string, CurrencyParams>>((acc, cur) => {
    if (cur.tokenPriceBusd === '0') {
      acc.set(cur.token.address, cur.token)
    }
    if (cur.quoteTokenPriceBusd === '0') {
      acc.set(cur.quoteToken.address, cur.quoteToken)
    }
    return acc
  }, new Map<string, CurrencyParams>())
  const tokenInfoList = Array.from(tokensWithoutPrice.values())
  if (tokenInfoList.length) {
    const prices = await getCurrencyListUsdPrice(tokenInfoList)

    return farmsDataWithPrices.map((f) => {
      if (f.tokenPriceBusd !== '0' && f.quoteTokenPriceBusd !== '0') {
        return f
      }
      const tokenKey = getCurrencyKey(f.token)
      const quoteTokenKey = getCurrencyKey(f.quoteToken)
      const tokenPrice = new BN(tokenKey ? prices[tokenKey] ?? 0 : 0)
      const quoteTokenPrice = new BN(quoteTokenKey ? prices[quoteTokenKey] ?? 0 : 0)
      const lpTokenPrice = getFarmLpTokenPrice(f, tokenPrice, quoteTokenPrice, decimals)
      return {
        ...f,
        tokenPriceBusd: tokenPrice.toString(),
        quoteTokenPriceBusd: quoteTokenPrice.toString(),
        lpTokenPrice: lpTokenPrice.toString(),
      }
    })
  }

  return farmsDataWithPrices
}

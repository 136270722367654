import React from "react";
import { styled } from "styled-components";
import { Colors } from "../../theme";
import Skeleton from "../Skeleton/Skeleton";
import LogoRound from "../Svg/Icons/LogoRound";
import Text from "../Text/Text";

export interface Props {
  color?: keyof Colors;
  cakePriceUsd?: number;
  showSkeleton?: boolean;
  chainId: number;
}

const PriceLink = styled.a`
  display: flex;
  align-items: center;
  svg {
    transition: transform 0.3s;
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
`;

const CakePrice: React.FC<React.PropsWithChildren<Props>> = ({
  cakePriceUsd,
  color = "textSubtle",
  showSkeleton = true,
  chainId,
}) => {
  if (cakePriceUsd === undefined && showSkeleton) return <Skeleton width={80} height={24} />;
  if (cakePriceUsd === undefined) return null;
  return (
    <PriceLink
      href="https://mancakeswap.finance/swap?outputCurrency=0xE2Ef8f6C96b2bEEE0F34B74bE5a2F4F37D22F7b1&chainId=5000"
      target="_blank"
    >
      <LogoRound width="24px" mr="8px" />
      <Text color={color} bold>{`$${cakePriceUsd.toFixed(3)}`}</Text>
    </PriceLink>
  );
};

export default React.memo(CakePrice);

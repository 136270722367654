import { ArrowForwardIcon, Button, FlexGap, Text } from "@pancakeswap/uikit";
import Link from "next/link";
import styled from "styled-components";
import { ActionTitles } from "./styles";

export interface UserPointProps {
  points: number;
}

const PointContainer = styled(Text)`
  font-size: 28px;
  font-weight: 500;

  > span {
    font-size: 16px;
    text-transform: uppercase;
    margin-left: 12px;
  }
`;

const UserPointV3Container = styled(FlexGap)`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.input};
  border-radius: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  padding: 20px;
`;

const UserPoint: React.FunctionComponent<React.PropsWithChildren<UserPointProps>> = ({ points }) => {
  return (
    <UserPointV3Container bg="background" gap="12px" justifyContent="space-between" alignItems="end">
      <FlexGap flexDirection="column" gap="4px">
        <ActionTitles>
          <Text bold textTransform="uppercase" color="secondary" fontSize="16px" pr="4px">
            your point
          </Text>
        </ActionTitles>
        <PointContainer>
          {Number(points).toLocaleString("en-US", { maximumFractionDigits: 4 })}
          <span>points</span>
        </PointContainer>
      </FlexGap>
      <Link style={{ height: "26px", display: "flex", alignItems: "center" }} href="/leaderboard" prefetch={false}>
        <Button p="0px" m="0px" variant="text">
          <Text color="primary" bold fontSize="16px" mr="8px">
            See Leaderboard
          </Text>
          <ArrowForwardIcon color="primary" />
        </Button>
      </Link>
    </UserPointV3Container>
  );
};

export default UserPoint;

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 36 36" {...props}>
      <path d="M32 4H28C28 1.79086 26.2091 0 24 0H12C9.7909 0 8 1.79086 8 4H4C1.8 4 0 5.8 0 8V10C0 15.1 3.84 19.26 8.78 19.88C10.04 22.88 12.74 25.14 16 25.8V32H10C8.8954 32 8 32.8954 8 34C8 35.1046 8.8954 36 10 36H26C27.1046 36 28 35.1046 28 34C28 32.8954 27.1046 32 26 32H20V25.8C23.26 25.14 25.96 22.88 27.22 19.88C32.16 19.26 36 15.1 36 10V8C36 5.8 34.2 4 32 4ZM4 10V8H8V15.64C5.68 14.8 4 12.6 4 10ZM32 10C32 12.6 30.32 14.8 28 15.64V8H32V10Z" />
    </Svg>
  );
};

export default Icon;

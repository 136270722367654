import { mantleTokens } from '@pancakeswap/tokens'
import { FeeAmount } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { SerializedFarmConfig } from '../src'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'

// TODO: Add Mantle Chain
export const farmsV3 = defineFarmV3Configs([
  // {
  //   pid: 1,
  //   token0: mantleTokens.test1, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.test2, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x16BCc2015c0214eD23f8949eAECF965CC91D07B8',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 1,
  //   token0: mantleTokens.jtest,
  //   token1: mantleTokens.ctest,
  //   lpAddress: '0x476dC8FCd73f5e2617231e3FB8b3513E4ffBf199',
  //   feeAmount: FeeAmount.LOWEST,
  // },
  // {
  //   pid: 2,
  //   token0: mantleTokens.jtest,
  //   token1: mantleTokens.ctest,
  //   lpAddress: '0x9DBdA0E1fb258AC90287DC3ddF91553D3F46bd3e',
  //   feeAmount: FeeAmount.LOWER,
  // },
  {
    pid: 3,
    token0: mantleTokens.jtest,
    token1: mantleTokens.ctest,
    lpAddress: '0x76C11D43FA651dDc8e60084D8F34cBfaf0697144',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 4,
    token0: mantleTokens.usdt,
    token1: mantleTokens.jtest,
    lpAddress: '0x575E6d22aaa6c6753eB8cd1D817CD2c577f5d17f',
    feeAmount: FeeAmount.LOWEST,
  },
  // {
  //   pid: 3,
  //   token0: mantleTokens.test1, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.test2, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x16BCc2015c0214eD23f8949eAECF965CC91D07B8',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 2,
  //   token0: mantleTokens.usdc, // 0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE
  //   token1: mantleTokens.wmnt, // 0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8
  //   lpAddress: '0x48AeaE837b8ebac441Ed8d00B6C5df6D3208C673',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
  // {
  //   pid: 3,
  //   token0: new ERC20Token(
  //     ChainId.MANTLE,
  //     '0x4206A257Dbc46e4145d31eE6b64717Bd455817a7',
  //     18,
  //     'TEST 1',
  //     'PancakeSwap Token',
  //     'https://mancakeswap.finance/',
  //   ),
  //   token1: mantleTokens.wmnt,
  //   lpAddress: '0xcb9C5e852d7874Ffab7F76DB78AF2cd05aFDd647',
  //   feeAmount: FeeAmount.MEDIUM,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 1,
    v1pid: 1,
    lpSymbol: 'A-JTEST LP',
    lpAddress: '0x1754F69669b74CDA879b5295e9Bb7FE3506ea27C',
    token: mantleTokens.stableA,
    quoteToken: mantleTokens.jtest,
    stableSwapAddress: '0x59dcbEd743Be740E0815E4A5fAB236F00e8Ad92E',
    infoStableSwapAddress: '0x0cF6c8b167e187C072f4F3e3E513fB2864648F53',
    stableLpFee: 0.0002,
    stableLpFeeRateOfTotalFee: 0.05,
  },
  // {
  //   pid: 0,
  //   v1pid: 0,
  //   lpSymbol: 'USDT-USDC LP',
  //   lpAddress: '0xf805D18e057450182df1b877319497344586Dbc8',
  //   token: mantleTokens.usdt,
  //   quoteToken: mantleTokens.usdc,
  //   stableSwapAddress: '0x1940Bf7e1d84A6f1f9F90A6290189601617736cb',
  //   infoStableSwapAddress: '0x17287d20cFd3bC884803816684333c853F2B06ef',
  //   stableLpFee: 0.0002,
  //   stableLpFeeRateOfTotalFee: 0.5,
  // },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

// const farms: SerializedFarmConfig[] = []

export default farms

import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useLocalStorage = (
  storageKey: string,
  fallbackState: string,
): [string, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState((localStorage.getItem(storageKey) || '') ?? fallbackState)

  useEffect(() => {
    localStorage.setItem(storageKey, value)
  }, [value, storageKey])

  return [value, setValue]
}

import { useTranslation } from "@pancakeswap/localization";
import { AddIcon, FlexGap, IconButton, MinusIcon, Text } from "@pancakeswap/uikit";
import { ReactNode } from "react";
import { ActionContent, ActionTitles, IconButtonWrapper, StyledActionContainer } from "./styles";

export interface StakedActionComponentProps {
  lpSymbol: string;
  children?: ReactNode;
  disabledMinusButton?: boolean;
  disabledPlusButton?: boolean;
  onPresentWithdraw: () => void;
  onPresentDeposit: () => void;
}

const StakedActionComponent: React.FunctionComponent<React.PropsWithChildren<StakedActionComponentProps>> = ({
  lpSymbol,
  children,
  disabledMinusButton,
  disabledPlusButton,
  onPresentWithdraw,
  onPresentDeposit,
}) => {
  const { t } = useTranslation();

  return (
    <StyledActionContainer>
      <FlexGap gap="12px" flexDirection="column" justifyContent="space-between">
        <ActionTitles style={{ marginBottom: "0px" }}>
          <Text bold color="secondary" fontSize="16px" pr="4px">
            {lpSymbol}
          </Text>
          <Text bold textTransform="uppercase" color="textSubtle" fontSize="16px">
            {t("Staked")}
          </Text>
        </ActionTitles>
        <ActionContent>
          {children}
          <IconButtonWrapper>
            <IconButton mr="6px" variant="secondary" disabled={disabledMinusButton} onClick={onPresentWithdraw}>
              <MinusIcon color="primary" width="14px" />
            </IconButton>
            <IconButton variant="secondary" disabled={disabledPlusButton} onClick={onPresentDeposit}>
              <AddIcon color="primary" width="14px" />
            </IconButton>
          </IconButtonWrapper>
        </ActionContent>
      </FlexGap>
    </StyledActionContainer>
  );
};

export default StakedActionComponent;

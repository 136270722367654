import { ChainId, getChainName } from '@pancakeswap/chains'

import { SerializedFarmConfig, isStableFarm, supportedChainIdV2 } from '..'

let logged = false

export const getFarmConfig = async (chainId?: ChainId) => {
  if (chainId && supportedChainIdV2.includes(chainId as number)) {
    const chainName = getChainName(chainId)
    try {
      const result = (await import(`/${chainName}.ts`)).default.filter(
        (f: SerializedFarmConfig) => f.pid !== null,
      ) as SerializedFarmConfig[]
      // const result = [
      //   {
      // pid: 1,
      // token: mantleTokens.ctest,
      // token0: mantleTokens.ctest,
      // token1: mantleTokens.jtest,
      // lpAddress: '0xaC8b0e1d22a40fb7225a36CE6249884900Af1545',
      // feeAmount: FeeAmount.LOWEST,
      // {
      // pid: 1,
      // token0: mantleTokens.wmnt,
      // token1: mantleTokens.jtest,
      // lpAddress: '0x62CFf64a27A15eceC60BfCDc0B479a36daea220F',
      // feeAmount: FeeAmount.MEDIUM,
      // },
      //   },
      // ]
      console.log(result, 'result')
      return result
    } catch (error) {
      if (!logged) {
        console.error('Cannot get farm config', error, chainId, chainName)
        logged = true
      }
      return []
    }
  }
  return undefined
}

export const getStableConfig = async (chainId: ChainId) => {
  if (supportedChainIdV2.includes(chainId as number)) {
    const chainName = getChainName(chainId)
    try {
      const farms = (await import(`/${chainName}.ts`)).default as SerializedFarmConfig[]

      return farms.filter(isStableFarm)
    } catch (error) {
      if (!logged) {
        console.error('Cannot get stable farm config', error, chainId, chainName)
        logged = true
      }
      return []
    }
  }
  return undefined
}

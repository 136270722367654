import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

const fetchRefStatusResponseSchema = z.object({
  referralCode: z.string(),
  referralStatus: z.enum(['PENDING', 'ACCEPTED', 'REJECTED', '']),
  allowApplyNewRefCode: z.boolean(),
  referredQty: z.number(),
})

export interface FetchRefStatusResponse {
  referralCode: string // empty string if user is not refer to any code
  referralStatus: 'PENDING' | 'ACCEPTED' | 'REJECTED' | '' // {PENDING, ACCEPTED, REJECTED}}, returns empty string if there's no applied referral code
  allowApplyNewRefCode: boolean

  referredQty: number // count number of my_code usage of other row that status is "SUCCESS"
}

/**
 * - Fetch the applied referral code status, whether it is pending, accepted, or rejected.
 */
const fetchRefStatus = async (chainId: string, userAddress: string): Promise<FetchRefStatusResponse> => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_MANCAKE_API_URL}/v1/referral/referral-info/${chainId}/${userAddress}`,
  )
  const json = await res.json()
  const parsed = fetchRefStatusResponseSchema.safeParse(json)

  if (!parsed.success) {
    throw new Error('Something went wrong while parsing the response')
  }

  return parsed.data as FetchRefStatusResponse
}

/**
 * - Fetch the applied referral code status, whether it is pending, accepted, or rejected.
 * - Fetch the many times has the referral code of the wallet address provided been used.
 * - If the wallet address is not provided, the query will not be executed.
 */
export const useRefStatusQuery = (address: `0x${string}` | undefined) => {
  const query = useQuery({
    queryKey: ['referral-status', address],
    queryFn: () => fetchRefStatus('5000', address!),
    enabled: !!address,
    refetchInterval: 10 * 1000,
    staleTime: 10 * 1000,
  })

  return query
}

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13 5H11V3.5C11 2.70435 10.6839 1.94129 10.1213 1.37868C9.55871 0.81607 8.79565 0.5 8 0.5C7.20435 0.5 6.44129 0.81607 5.87868 1.37868C5.31607 1.94129 5 2.70435 5 3.5V5H3C2.73478 5 2.48043 5.10536 2.29289 5.29289C2.10536 5.48043 2 5.73478 2 6V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H13C13.2652 14 13.5196 13.8946 13.7071 13.7071C13.8946 13.5196 14 13.2652 14 13V6C14 5.73478 13.8946 5.48043 13.7071 5.29289C13.5196 5.10536 13.2652 5 13 5ZM6 3.5C6 2.96957 6.21071 2.46086 6.58579 2.08579C6.96086 1.71071 7.46957 1.5 8 1.5C8.53043 1.5 9.03914 1.71071 9.41421 2.08579C9.78929 2.46086 10 2.96957 10 3.5V5H6V3.5ZM13 13H3V6H13V13ZM8.75 9.5C8.75 9.64834 8.70601 9.79334 8.6236 9.91668C8.54119 10.04 8.42406 10.1361 8.28701 10.1929C8.14997 10.2497 7.99917 10.2645 7.85368 10.2356C7.7082 10.2067 7.57456 10.1352 7.46967 10.0303C7.36478 9.92544 7.29335 9.7918 7.26441 9.64632C7.23547 9.50083 7.25032 9.35003 7.30709 9.21299C7.36386 9.07594 7.45999 8.95881 7.58332 8.8764C7.70666 8.79399 7.85166 8.75 8 8.75C8.19891 8.75 8.38968 8.82902 8.53033 8.96967C8.67098 9.11032 8.75 9.30109 8.75 9.5Z"
        fill="currentColor"
      />
    </Svg>
  );
};

export default Icon;

import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useMutation, useSignMessage } from 'wagmi'
import { z } from 'zod'
import { FetchRefStatusResponse } from './useRefStatusQuery'

export type ApplyRefCodeRequest = {
  signature: string
  signedData: {
    chainId: number
    userAddress: string
    referralCode: string
    version: string
  }
}

export const useApplyRefCodePayload = () => {
  const { signMessageAsync } = useSignMessage()

  const createPayload = useCallback(
    async (address: `0x${string}`, refCode: string): Promise<ApplyRefCodeRequest> => {
      if (!address) throw new Error('Please connect your wallet')

      const data: ApplyRefCodeRequest['signedData'] = {
        chainId: 5000,
        userAddress: address,
        referralCode: refCode,
        version: '1',
      }

      const dataToSign = {
        protocol: 'mancakeswap.finance',
        type: 'apply_referral_code',
        data,
      }

      const signature = await signMessageAsync({ message: JSON.stringify(dataToSign) })

      return {
        signature,
        signedData: data,
      }
    },
    [signMessageAsync],
  )

  return {
    /**
     * Sign a message to generate a request payload
     */
    createPayload,
  }
}

const applyRefCodeResponseSchema = z.object({
  msg: z.enum(['success', 'user has applied another code']),
  referralCode: z.string(),
})

type MutateApplyRefCodeResponse = {
  msg: 'success' | 'user has applied another code'
  referralCode: string
}

const applyRefCodeResponseErrorSchema = z.object({
  msg: z.string(),
})

async function applyRefCode(payload: ApplyRefCodeRequest): Promise<MutateApplyRefCodeResponse> {
  const res = await fetch(`${process.env.NEXT_PUBLIC_MANCAKE_API_URL}/v1/referral/code/apply`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })

  const json = await res.json()

  if (!res.ok) {
    const parsed = applyRefCodeResponseErrorSchema.parse(json)
    throw new Error(parsed.msg || 'Something went wrong.')
  }

  const parsed = applyRefCodeResponseSchema.safeParse(json)

  if (!parsed.success) {
    throw new Error('Something went wrong while parsing the response')
  }

  return parsed.data as MutateApplyRefCodeResponse
}

/**
 * Mutate function for applying referral code.
 * Takes currentWallet for caching purposes
 */
export const useApplyRefCodeMutate = (currentWallet: `0x${string}` | undefined) => {
  const queryClient = useQueryClient()

  const mutate = useMutation({
    mutationKey: ['apply-ref', currentWallet],
    mutationFn: applyRefCode,
    onMutate: async (request) => {
      const address = request.signedData.userAddress
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['referral-status', address] })

      // Snapshot the previous value
      const previousData: FetchRefStatusResponse = queryClient.getQueryData(['referral-status', address])!

      // Optimistically update to the new value
      queryClient.setQueryData(['referral-status', address], {
        ...previousData,
        referralCode: request.signedData.referralCode,
        referredQty: 0,
        referralStatus: 'PENDING',
      } as FetchRefStatusResponse)

      // Return a context object with the snapshotted value
      return { previousData }
    },
    onError: async (error, variables, context) => {
      // Roll back to the previous value
      if (context) {
        queryClient.setQueryData(['referral-status', variables.signedData.userAddress], context.previousData)
      }
    },
  })

  return mutate
}

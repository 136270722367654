import { useCallback } from 'react'
import { useApplyRefCodeMutate, useApplyRefCodePayload } from './useApplyRefCodeMutate'
import { useRefStatusQuery } from './useRefStatusQuery'

/**
 * If the address is not provided, it disables the referral query
 */
export const useApplyReferralCode = (address: `0x${string}` | undefined) => {
  /**
   * Read Referral Code
   */
  const query = useRefStatusQuery(address)

  const { createPayload } = useApplyRefCodePayload()
  const applyRefCodeMutate = useApplyRefCodeMutate(address)

  const applyReferralCode = useCallback(
    async (addr: `0x${string}`, referralCode: string) => {
      const payload = await createPayload(addr, referralCode)
      const res = await applyRefCodeMutate.mutateAsync(payload)
      return res
    },
    [applyRefCodeMutate, createPayload],
  )

  return {
    query,
    applyReferralCode: {
      mutateAsync: applyReferralCode,
      isLoading: applyRefCodeMutate.isLoading,
      isError: applyRefCodeMutate.isError,
    },
  }
}
